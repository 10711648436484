import React, { FC } from "react";
import Link from "next/link";
import { Typography } from "@mui/material";

interface Props {
  href: string;
  value: string;
  isActive: boolean;
}

const NavLink: FC<Props> = ({ href, value, isActive }) => {
  return (
    <Link href={href}>
      <Typography
        component="li"
        sx={{
          color: isActive ? "primary.main" : "secondary.main",
          fontSize: {
            xs: "40px",
            md: "14px",
          },
          fontWeight: {
            xs: isActive ? 600 : 400,
            md: 600,
          },
          listStyleType: "none",
          cursor: "pointer",
          "&:hover": {
            color: "primary.main",
          },
        }}
      >
        {value}
      </Typography>
    </Link>
  );
};

export default NavLink;
