import { Box, Typography } from "@mui/material";
import React, { FC } from "react";

import useSubscriptionPlan from "../common/useSubscriptionPlan";

const TierColors: any = {
  Free: {
    color: "#66B045",
    backgroundColor: "rgba(127, 176, 105, 0.1)",
  },
  Plus: {
    color: "#FB8932",
    backgroundColor: "rgba(251, 137, 50, 0.1)",
  },
  Pro: {
    color: "#E75476",
    backgroundColor: "rgba(251, 137, 50, 0.2)",
  },
  Enterprise: {
    color: "#8060FB",
    backgroundColor: "rgba(128, 96, 251, 0.1);",
  },
};

const TierIndicator = () => {
  const { plan, isLogged } = useSubscriptionPlan();

  return (
    <Box
      component="p"
      sx={{
        px: 2,
        py: "5px",
        fontWeight: 600,
        borderRadius: "10px",
        ...TierColors[isLogged ? plan || "Free" : "Free"],
        fontFamily: "Poppins",
      }}
    >
      {plan || "Free"}
    </Box>
  );
};

export default TierIndicator;
