import React, { FC } from "react";

import FullLogo from "./../../../public/fullLogo.svg";
import SLogo from "./../../../public/logo.svg";
import AppSumo from "./../../../public/appsumo.svg";

interface Props {
  type?: "textBig" | "image" | "textSmall" | "appSumo";
  url?: string;
}

const FILES = {
  textBig: {
    src: FullLogo,
    width: 105,
    height: 32,
  },
  appSumo: {
    src: AppSumo,
    width: 262,
    height: 32,
  },
  textSmall: {
    src: FullLogo,
    width: 52,
    height: 52,
  },
  image: {
    src: SLogo,
    width: 30,
    height: 35,
  },
};

const home =
  // ts-ignore()
  process.env.NODE_ENV === "production"
    ? "https://dashboard.joyqr.com"
    : "https://test.joyqr.com";

const Logo: FC<Props> = ({ url, type = "textBig" }) => {
  const Component = FILES[type].src;
  return (
    <a href={url || home}>
      <Component width={FILES[type].width} height={FILES[type]?.height} />
    </a>
  );
};

export default Logo;
