import React, { FC, useEffect, useState } from "react";
import { Stack, useMediaQuery } from "@mui/material";
import { destroyCookie } from "nookies";
import DesktopLinks from "./DesktopLinks";
import DropdownMenu from "./DropdownMenu";
import MobileLinks from "./MobileLinks";
import { useRouter } from "next/router";
import useActiveDashboard from "../../../../utils/useActiveDashboard";
import useInfoBanner from "../../../reusable/banners/useInfoBanner";

export const logout = () => {
  destroyCookie(null, "joytoken", {
    path: "/",
    domain: process.env.NEXT_PUBLIC_DOMAIN,
    sameSite: "Lax",
  });
  setTimeout(() => {
    location.replace("/auth/signin");
  }, 100);
};

interface Props {
  isPublic?: boolean;
}

const Nav: FC<Props> = ({ isPublic = false }) => {
  const [menuTop, setMenuTop] = useState(40);

  const { confirmationBanner } = useInfoBanner(isPublic);
  const dashboard = useActiveDashboard();
  const isQrLinkDashboard = dashboard === "link";

  useEffect(() => {
    const h = document.getElementById("confirm-banner")?.clientHeight || 40;
    setMenuTop(h);
  }, []);

  const match = useMediaQuery("(max-width: 900px)");
  return (
    <Stack
      id="nav"
      component="nav"
      flexDirection={"row"}
      justifyContent="center"
      sx={{
        position: "sticky",
        left: 0,
        top: confirmationBanner ? menuTop : "0",
        pt: "48px",
        pb: "16px",
        zIndex: 1300,
        backgroundColor: isQrLinkDashboard ? "#ffffff" : "#F5F5F5",
      }}
    >
      {!match ? (
        <Stack
          component="ul"
          flexDirection={"row"}
          justifyContent="space-between"
          sx={{
            listStyleType: "none",
            width: "100%",
            maxWidth: 1200,
          }}
        >
          <DesktopLinks isPublic={isPublic} isSingleLink={isQrLinkDashboard} />
          <DropdownMenu isPublic={isPublic} />
        </Stack>
      ) : (
        <MobileLinks isPublic={isPublic} />
      )}
    </Stack>
  );
};

export default Nav;
