import mixpanel, { OverridedMixpanel } from "mixpanel-browser";
import React, { createContext, FC, PropsWithChildren, useContext } from "react";

const context = createContext<OverridedMixpanel>({} as OverridedMixpanel);

export const Consumer = context.Consumer;
export const Provider = context.Provider;

export const useMixpanel = () => useContext(context);

const MixpanelProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL as string);
  return <Provider value={mixpanel}>{children}</Provider>;
};

export default MixpanelProvider;
