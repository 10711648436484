export const DASHBOARD_MENU = [
  {
    value: "Links",
    href: "/",
  },
  {
    value: "Appearance",
    href: "/appearance",
  },
  {
    value: "Analytics",
    href: "/tanalytics/insights?t=Bio&q=all",
  },
  {
    value: "Settings",
    href: "/settings",
  },
  /*{
    value: "Subscribers",
    href: "/subscribers",
  },
  {
    value: "PRO",
    href: "/pricing",
  },*/
];

export const NOT_SELECTED_FEATURE_MENU = [
  {
    value: "Create QR Code",
    href: "/qrlink",
  },
  {
    value: "Create Bio Page",
    href: "/dashboard",
  },
];

export const SINGLE_LINK_TOOL_MENU = [
  {
    value: "My QR Codes",
    href: "/qrlink",
  },
  {
    value: "Create QR code",
    href: "/qrlink/createqr",
  },
  {
    value: "Folders",
    href: "/qrlink/category",
  },
  {
    value: "Analytics",
    href: "/tanalytics/insights?t=Qr&q=all",
  },
];
