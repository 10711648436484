import React, { FC, useState } from "react";
import {
  SelectedFeature,
  useSelectFeatureMutation,
} from "../../../../generated/graphql";
import { useRouter } from "next/router";
import { useMixpanel } from "../common/useMixpanel";
import { Stack, Box, Typography, Button } from "@mui/material";
//   className={isReturning ? `old-user-bio` : `new-user-bio`}>

interface Props {
  isReturning: boolean;
  userId: string;
}

const BoxCss = {
  pb: {
    xs: 2,
    md: 4,
  },
  pt: {
    xs: 2,
    md: 5,
  },
  px: {
    xs: "4px",
    md: 5,
  },
  width: {
    xs: "100%",
    sm: "calc(100vw / 3)",
    md: "300px",
  },
  height: {
    xs: "auto",
    md: "300px",
  },
  backgroundColor: "#F7F7F7",
  borderRadius: "22px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  cursor: "pointer",
  flexDirection: "column",
  "&:hover": {
    outline: "3px solid #0F75FB",
  },
};

const FeatureCrossroadPage: FC<Props> = ({ isReturning, userId }) => {
  const mixpanel = useMixpanel();

  const [select] = useSelectFeatureMutation();
  const { push } = useRouter();

  const proccedToDashboard = (feature: SelectedFeature) => {
    if (feature === SelectedFeature.None) return;

    const f =
      feature === SelectedFeature.BioLink
        ? "Selected Bio Page"
        : "Selected QR Link";

    const d = feature === SelectedFeature.BioLink ? "/" : "/qrlink";

    mixpanel.track(f, {
      source: "QR/Bio Crossroad page",
    });
    select({
      variables: {
        feature: feature,
      },
      optimisticResponse: {
        selectFeature: feature,
      },
      update: (cache, data) => {
        cache.modify({
          id: `Customer:${userId}`,
          fields: {
            selectedFeature(cachedName) {
              return feature;
            },
          },
        });
      },
    });
    push(d);
  };
  return (
    <Stack
      sx={{
        p: {
          xs: 3,
          md: 8,
        },
        borderRadius: 2,
        border: "1px solid #E0E0E0",
        justifyContent: "center",
        rowGap: 3,
        alignItems: "center",
      }}
    >
      <Typography variant="body2">Glad to have you on board!</Typography>
      <Typography textAlign="center" maxWidth={570}>
        Before we direct you to your new dashboard, please tell us which
        function you will like to start with{" "}
        <b>
          Don’t worry, you can toggle between each function at any given time!
        </b>
      </Typography>
      <Stack
        sx={{
          width: "100%",
          flexDirection: {
            xs: "row",
            sm: "row",
          },
        }}
        rowGap={2}
        columnGap={3}
      >
        <Box
          tabIndex={0}
          onClick={() => proccedToDashboard(SelectedFeature.SingleLink)}
          sx={{
            ...BoxCss,
          }}
        >
          <Box
            component="img"
            src={"https://joyqr-prod.sfo3.digitaloceanspaces.com/qr.png"}
            sx={{
              m: "auto",
              width: {
                xs: "60%",
                md: "80%",
                sm: "100%",
              },
              height: "auto",
              maxWidth: "100px",
            }}
          />
          <Typography
            variant="body1"
            color={"primary"}
            mt={1}
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
            }}
          >
            Create QR Code
          </Typography>
        </Box>
        <Box
          tabIndex={-1}
          onClick={() => proccedToDashboard(SelectedFeature.BioLink)}
          sx={{
            ...BoxCss,
          }}
        >
          <Box
            component="img"
            src={"https://joyqr-prod.sfo3.digitaloceanspaces.com/bio.png"}
            sx={{
              m: "auto",
              width: {
                xs: "60%",
                md: "80%",
                sm: "100%",
              },
              height: "auto",
              maxWidth: "100px",
            }}
          />
          <Typography
            variant="body1"
            color={"primary"}
            mt={1}
            sx={{
              fontSize: {
                xs: 12,
                md: 16,
              },
            }}
          >
            Create Bio Page
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FeatureCrossroadPage;
