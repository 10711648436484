import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import {
  DASHBOARD_MENU,
  SINGLE_LINK_TOOL_MENU,
} from "../../../../config/dashboard";
import Logo from "../../../reusable/Logo";
import NavLink from "./NavLink";

import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Divider, Stack } from "@mui/material";
import DashboardSwitch from "./DashboardSwitch";
import DropdownMenu from "./DropdownMenu";

interface Props {
  isPublic?: boolean;
}

const MobileLinks: FC<Props> = ({ isPublic }) => {
  const router = useRouter();

  const [isOpened, setOpened] = useState(false);

  const onHamburgerClick = () => {
    setOpened(!isOpened);
  };

  useEffect(() => {
    setOpened(false);
  }, [router.asPath]);

  const LOGO_URL = !router.asPath.includes("qrlink") ? "/" : "/qrlink";

  const LINKS = !router.asPath.includes("qrlink")
    ? DASHBOARD_MENU
    : SINGLE_LINK_TOOL_MENU;

  return (
    <Stack
      bgcolor={"#fff"}
      flexDirection="row"
      columnGap={"20px"}
      justifyContent="space-between"
      sx={{
        alignItems: "center",
        px: "20px",
        py: "14.5px",
        left: 0,
        top: 0,
        position: "absolute",
        width: "100%",
      }}
    >
      <Box>
        <Logo type="image" url={LOGO_URL} />
      </Box>
      <DashboardSwitch isPublic={isPublic} />
      <MenuIcon onClick={onHamburgerClick} />
      {isOpened && (
        <Stack
          sx={{
            position: "absolute",
            width: "100%",
            px: "20px",
            backgroundColor: "inherit",
            left: 0,
            py: "14.5px",
            top: 68,
            height: "100vh",
          }}
        >
          <DropdownMenu />
          <Divider sx={{ mt: 4 }} />
          <Stack>
            {LINKS.map((link, key) => (
              <NavLink
                key={key}
                {...link}
                isActive={router.pathname === link.href}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default MobileLinks;
