import { Box, Stack } from "@mui/material";
import Router, { useRouter } from "next/router";
import React, { FC } from "react";
import {
  DASHBOARD_MENU,
  SINGLE_LINK_TOOL_MENU,
} from "../../../../config/dashboard";
import { useMeQuery } from "../../../../generated/graphql";
import Logo from "../../../reusable/Logo";
import DashboardSwitch from "./DashboardSwitch";
import NavLink from "./NavLink";

interface Props {
  isPublic?: boolean;
  isSingleLink: boolean;
}

const getUrl = (url: string) => {
  const [activeUrl] = url.split("?");
  return activeUrl;
};

const DesktopLinks: FC<Props> = ({ isPublic, isSingleLink }) => {
  const router = useRouter();

  const LINKS = isPublic
    ? SINGLE_LINK_TOOL_MENU
    : !isSingleLink
      ? DASHBOARD_MENU
      : SINGLE_LINK_TOOL_MENU;

  const LOGO_URL = !isSingleLink ? "/" : "/qrlink";

  return (
    <Stack
      bgcolor={"#fff"}
      flexDirection="row"
      columnGap={"20px"}
      justifyContent="space-between"
      sx={{
        borderRadius: "25px",
        alignItems: "center",
        px: "20px",
        height: "50px",
        py: "13px",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
        //maxWidth: "580px",
        width: "fit-content",
      }}
    >
      <Box
        sx={{
          transform: "scale(0.8) translateY(4px)",
        }}
      >
        <Logo type="image" url={LOGO_URL} />
      </Box>
      {LINKS.map((link, key) => (
        <NavLink
          key={key}
          {...link}
          isActive={getUrl(router.pathname) === getUrl(link.href)}
        />
      ))}
    </Stack>
  );
};

export default DesktopLinks;
