import { Avatar, Stack, Typography, useMediaQuery } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useEffect, useState } from "react";
import { logout } from "./Nav";
import TierIndicator from "./TierIndicator";
import UploadIndicator from "./UploadIndicator";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import DashboardSwitch from "./DashboardSwitch";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useMeQuery } from "../../../../generated/graphql";

//@ts-ignore()
import md5 from "blueimp-md5";

const MenuItems = [
  {
    value: "My Account",
    href: "/account",
  },
  {
    value: "Redeem Coupon",
    href: "/redeem-coupon",
    displayForPlan: ["free"],
  },
  {
    value: "My Subscription",
    href: "/pricing",
  },
  {
    value: "My Pages",
    href: "/pages",
  },
];

interface Props {
  isPublic?: boolean;
}

const DropdownMenu: FC<Props> = ({ isPublic }) => {
  const { data } = useMeQuery({
    skip: isPublic,
  });

  const redirectToStripe = data
    ? data?.me.subscriptionPlan.id !== "sub_manual_admin" &&
      data?.me.subscriptionPlan.id !== "sub_default_Pro" &&
      data?.me.subscriptionPlan.id !== "sub_default_Plus" &&
      data?.me.subscriptionPlan.id !== "sub_default_Enterprise" &&
      !data?.me.subscriptionPlan.id.includes("sub_promo") &&
      data?.me.subscriptionPlan.id !== "free"
    : false;

  const redirectToPricing = data
    ? data?.me.subscriptionPlan.id === "free"
    : false;

  const router = useRouter();
  const [isOpened, setIsOpened] = useState(false);
  const match = useMediaQuery("(max-width: 900px)");

  const onChangeOpenState = () => {
    setIsOpened(!isOpened);
  };

  const avt = data
    ? data?.me.avatar ||
      `https://www.gravatar.com/avatar/${md5(
        data?.me.email
      )}"?default=${encodeURIComponent("aa")}`
    : "";

  return (
    <Stack
      flexDirection={"row"}
      alignItems="center"
      columnGap={1}
      position="relative"
      justifyContent={["space-between", "space-between", "initial"]}
    >
      {match ? (
        <>
          <Stack flexDirection={"row"} alignItems="center">
            <Avatar
              onClick={onChangeOpenState}
              src={avt}
              sx={{
                width: "45px",
                height: "45px",
                cursor: "pointer",
              }}
            />
            <MoreVertIcon
              onClick={onChangeOpenState}
              sx={{
                cursor: "pointer",
                transform: "translateX(0px)",
              }}
            />
          </Stack>
          <TierIndicator />
        </>
      ) : (
        <>
          <DashboardSwitch isPublic={isPublic} />
          {/**
 * <HelpOutlineIcon
            sx={{
              mx: 2,
              color: "system.main",
              cursor: "pointer",
            }}
          />
 */}
          {!isPublic && <UploadIndicator />}
          <TierIndicator />
          <Avatar
            onClick={onChangeOpenState}
            src={avt}
            sx={{
              width: "45px",
              height: "45px",
              cursor: "pointer",
            }}
          />
          <MoreVertIcon
            onClick={onChangeOpenState}
            sx={{
              cursor: "pointer",
              transform: "translateX(-5px)",
            }}
          />
        </>
      )}
      {isOpened && (
        <ClickAwayListener onClickAway={onChangeOpenState}>
          <Stack
            sx={{
              zIndex: 5,
              borderRadius: "13px",
              px: "7px",
              py: "20px",
              position: "absolute",
              right: 0,
              left: [0, 0, "initial"],
              top: "65px",
              width: "180px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
            }}
          >
            {MenuItems.map(({ href, value, displayForPlan }, k) => {
              if (href === "/pricing") {
                if (redirectToPricing) {
                  return (
                    <Link key={k} href={href}>
                      <Typography
                        component="a"
                        sx={{
                          py: "10px",
                          pr: "23px",
                          textAlign: "right",
                          cursor: "pointer",
                          backgroundColor:
                            router.pathname === href ? "#F3F4F5" : "inherit",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {value}
                      </Typography>
                    </Link>
                  );
                }

                if (redirectToStripe) {
                  return (
                    <form
                      method="POST"
                      action={`${process.env.NEXT_PUBLIC_URL}payment/customer-portal-session`}
                    >
                      <Typography
                        component="button"
                        type="submit"
                        sx={{
                          py: "10px",
                          pr: "23px",
                          textAlign: "right",
                          cursor: "pointer",
                          border: "none",
                          width: "100%",
                          backgroundColor:
                            router.pathname === href ? "#F3F4F5" : "inherit",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        {value}
                      </Typography>
                    </form>
                  );
                }

                return null;
              }

              if (
                displayForPlan &&
                data?.me.subscriptionPlan.id &&
                !displayForPlan.includes(data?.me.subscriptionPlan.id)
              )
                return null;

              return (
                <Link key={k} href={href}>
                  <Typography
                    component="a"
                    sx={{
                      py: "10px",
                      pr: "23px",
                      textAlign: "right",
                      cursor: "pointer",
                      backgroundColor:
                        router.pathname === href ? "#F3F4F5" : "inherit",
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                  >
                    {value}
                  </Typography>
                </Link>
              );
            })}
            <Typography
              component="a"
              onClick={() => logout()}
              sx={{
                py: "10px",
                pr: "23px",
                textAlign: "right",
                fontWeight: 600,
                color: "error.main",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              Log Out
            </Typography>
          </Stack>
        </ClickAwayListener>
      )}
    </Stack>
  );
};

export default DropdownMenu;

/**
 *             <form
                  method="POST"
                  action={`${process.env.NEXT_PUBLIC_URL}payment/customer-portal-session`}>
                  <button
                    type="submit"
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}>
                    {" "}
                    <Text typo="a" value="My Subscription" size="14" />
                  </button>
                </form>
 */
