import { useRouter } from "next/router";
import React from "react";
import client from "../config/apollo";
import { MeDocument, MeQuery, SelectedFeature } from "../generated/graphql";

const useActiveDashboard = () => {
  const user = client.readQuery({
    query: MeDocument,
  });

  const { asPath } = useRouter();

  if (!!asPath.match("tanalytics")) {
    return user?.me.selectedFeature === SelectedFeature.SingleLink
      ? "link"
      : "bio";
  }

  let isMatch = false;
  const isQrLinkDashboard = [
    "qrlink",
    "pages",
    "account",
    "qrcode",
    "pricing",
    "qrgenerator",
    "domains",
    "redeem-coupon",
  ];

  isQrLinkDashboard.forEach((l) => {
    if (isMatch) return;
    isMatch = !!asPath.match(l);
  });

  return isMatch ? "link" : "bio";
};

export default useActiveDashboard;
