import React, { FC, PropsWithChildren } from "react";
import {
  Stack,
  ThemeProvider as ThemeProviderMui,
  Typography,
} from "@mui/material";
import Link from "next/link";

interface Props {
  enabled: boolean;
  isPublic: boolean;
}

const TrialBanner: FC<Props> = ({ isPublic, enabled }) => {
  return enabled ? (
    <Stack
      sx={{
        backgroundColor: "#FB8932",
        py: "20px",
      }}
    >
      <Typography
        component="div"
        fontWeight={500}
        color="white"
        textAlign="center"
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          columnGap: 1,
          flexWrap: "wrap",
        }}
      >
        Get a 1-month free trial PRO account, cancel anytime.{" "}
        {isPublic ? (
          <Link href="/pricing">
            <Typography
              fontWeight={600}
              color="white"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "none",
                },
              }}
              component="a"
            >
              Sign up for free trial
            </Typography>
          </Link>
        ) : (
          <span>
            <form
              style={{
                width: "max-content",
              }}
              action={`${process.env.NEXT_PUBLIC_URL}payment/create-checkout-session`}
              method="POST"
            >
              <input
                type="hidden"
                name="priceId"
                value={process.env.NEXT_PUBLIC_PRO_MONTH_PRICE_KEY as string}
              />
              <Typography
                fontWeight={600}
                color="white"
                sx={{
                  textDecoration: "underline",
                  border: "none",
                  backgroundColor: "transparent",
                  cursor: "pointer",
                  "&:hover": {
                    textDecoration: "none",
                  },
                }}
                component="button"
              >
                Sign up for free trial
              </Typography>
            </form>
          </span>
        )}
      </Typography>
    </Stack>
  ) : null;
};

export default TrialBanner;
