import React from "react";
import { useMeQuery } from "../../../generated/graphql";

export default (isPublic: boolean) => {
  const { data } = useMeQuery({
    skip: isPublic,
  });

  const trialBanner = isPublic
    ? true
    : (!["Pro", "Enterprise"].includes(data?.me?.subscriptionPlan.name || "") &&
        data?.me.subscriptionPlan.canTrial &&
        data?.me.active) ||
      false;
  const confirmationBanner = isPublic ? false : data ? !data?.me.active : false;

  return {
    trialBanner,
    confirmationBanner,
  };
};
