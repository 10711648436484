import {
  Icon,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { FC, useEffect } from "react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import RememberMeIcon from "@mui/icons-material/RememberMe";
import { useRouter } from "next/router";
import { SelectedFeature, useMeQuery } from "../../../../generated/graphql";
import client from "../../../../config/apollo";
import useActiveDashboard from "../../../../utils/useActiveDashboard";

interface Props {
  isPublic?: boolean;
}

const DashboardSwitch: FC<Props> = ({ isPublic = false }) => {
  const { data } = useMeQuery({
    skip: isPublic,
  });
  const match = useMediaQuery("(max-width: 500px)");
  const dashboard = useActiveDashboard();

  const getIsBio = () => {
    if (isPublic) return false;

    return dashboard === "bio";

    /*  if (
        data?.me.selectedFeature === SelectedFeature.BioLink ||
        data?.me.selectedFeature === SelectedFeature.None
      ) {
        return true;
      }*/
  };

  const { push, asPath } = useRouter();

  const isBio = getIsBio();

  const switchDashboard = (dashboard: SelectedFeature) => {
    const redirect: any = {
      [SelectedFeature.BioLink]: "/",
      [SelectedFeature.SingleLink]: "/qrlink",
    };

    client.cache.modify({
      id: `Customer:${data?.me.id}`,
      fields: {
        selectedFeature(c) {
          return dashboard;
        },
      },
    });

    push(redirect[dashboard]);
  };

  useEffect(() => {
    const redirect: any = {
      [SelectedFeature.BioLink]: "/",
      [SelectedFeature.SingleLink]: "/qrlink",
    };

    const t: any =
      data?.me.selectedFeature === SelectedFeature.None
        ? SelectedFeature.BioLink
        : data?.me.selectedFeature;
    if (isPublic === false && asPath === "/") {
      push(redirect[t]);
    }
  }, []);

  return (
    <Stack
      flexDirection={"row"}
      columnGap={1}
      alignItems="center"
      sx={{
        backgroundColor: "#fff",
        p: "3px",
        borderRadius: "100px",
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Typography
        onClick={() => switchDashboard(SelectedFeature.BioLink)}
        sx={{
          p: "9px",
          cursor: "pointer",
          alignItems: "center",
          fontWeight: 500,
          fontSize: 14,
          backgroundColor: isBio ? "purple.main" : "transparent",
          "&:hover": {
            color: !isBio ? "#1E74E5" : "#ffffff",
          },
          borderRadius: "100px",
          color: isBio ? "#ffffff" : "primary.main",
          display: {
            xs: "flex",
            sm: "flex",
          },
        }}
      >
        <RememberMeIcon
          sx={{
            color: isBio ? "#ffffff" : "primary.main",
            fontSize: "16px",
            mr: "7px",
          }}
        />
        {match ? "Bio" : "Manage Bio Page"}
      </Typography>

      <Typography
        onClick={() => switchDashboard(SelectedFeature.SingleLink)}
        sx={{
          p: "9px",
          cursor: "pointer",
          alignItems: "center",
          fontWeight: 500,
          fontSize: 14,
          borderRadius: "100px",
          backgroundColor: !isBio ? "info.main" : "transparent",
          "&:hover": {
            color: isBio ? "primary.hover" : "#fff",
          },
          display: {
            xs: "flex",
            sm: "flex",
          },
          color: isBio ? "primary.main" : "#fff",
        }}
      >
        <QrCodeScannerIcon
          sx={{
            color: isBio ? "inherit" : "#fff",
            fontSize: "16px",
            mr: "7px",
          }}
        />
        {match ? "QR" : "Manage QR Code"}
      </Typography>
    </Stack>
  );
};

export default DashboardSwitch;
