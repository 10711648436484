import React, { FC, PropsWithChildren, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import Head from "next/head";
import { useMeQuery, SelectedFeature } from "../../../../generated/graphql";
import { createFontsUrl } from "../../../../utils";
import { PointsLoader } from "../../../../ui/Loader/Loader";
import Nav from "../nav/Nav";
import FeatureCrossroadPage from "./FeatureCrossroadPage";
import { useModal } from "../../../../ui/Modal";
import { parseCookies, setCookie } from "nookies";
import { FONT_LIST } from "../page-generator/state";
import Notification from "../../../../ui/Notification";

interface Props {}

const DashboadContainer: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { openModal, renderModal, isOpened, closeModal } = useModal();

  const { data, loading, error } = useMeQuery();
  const { asPath, push } = useRouter();

  useEffect(() => {
    document.getElementById("content")?.scroll(0, 0);
  }, [asPath]);

  const isFeatureSelected = data?.me.selectedFeature !== SelectedFeature.None;

  useEffect(() => {
    if (data?.me) {
      if (!isOpened && !isFeatureSelected && !asPath.includes("/pricing")) {
        openModal(
          <FeatureCrossroadPage isReturning={isOldUser} userId={data.me.id} />
        );
      } else if (isOpened && isFeatureSelected) {
        closeModal();
      }
    }
  }, [isOpened, isFeatureSelected, asPath]);

  useEffect(() => {
    if (!loading && data?.me) {
      if (!isFeatureSelected && !asPath.includes("/pricing")) {
        openModal(
          <FeatureCrossroadPage isReturning={isOldUser} userId={data.me.id} />
        );
      }
    }
  }, [loading, isFeatureSelected, asPath]);

  /*  useEffect(() => {
      if (data?.me && isFeatureSelected) {
        const redirect: any = {
          [SelectedFeature.BioLink]: '/',
          [SelectedFeature.SingleLink]: '/qrlink',
        }
        push(redirect[data?.me.selectedFeature])
      }
    }, [data?.me])*/

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <PointsLoader />
      </div>
    );
  }

  if (!data?.me) {
    window.location.replace("/auth/signin");
    return null;
  }

  const isOldUser = new Date(data.me.createdAt) < new Date("4/14/2022");

  //@ts-ignore
  /*if (window?.Intercom) {
    //@ts-ignore
    window?.Intercom("boot", {
      app_id: "z0l72ty3",
      name: data.me.fullName,
      user_id: data.me.id,
      email: data.me.email,
      created_at: Math.floor(new Date(data.me.createdAt).getTime() / 1000), // Signup date as a Unix timestamp
    });
  }*/

  return (
    <>
      {renderModal()}
      <Nav />
      <Head>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href={`https://fonts.googleapis.com/css2?${createFontsUrl(
            FONT_LIST
          )}&display=swap`}
          rel="stylesheet"
        />
      </Head>
      <Notification />
      {children}
    </>
  );
};

export default DashboadContainer;
