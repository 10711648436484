import { Stack, Typography } from "@mui/material";
import Link from "next/link";
import React, { FC, useState } from "react";
import { useResendActivationMailMutation } from "../../../generated/graphql";

interface Props {
  enabled: boolean;
}

const ConfirmAlert: FC<Props> = ({ enabled }) => {
  const [resend, setResend] = useState(false);

  const [resendMutation, { loading }] = useResendActivationMailMutation({
    onCompleted(data) {
      setResend(true);
    },
  });

  const onResendClick = async () => {
    resendMutation();
  };

  return enabled ? (
    <Stack
      id="confirm-banner"
      sx={{
        backgroundColor: "rgba(2, 43, 81, 0.9)",
        py: "5px",
        position: "sticky",
        top: 0,
        zIndex: 1300,
      }}
    >
      <Typography
        fontWeight={500}
        color="white"
        textAlign="center"
        display="flex"
        flexDirection={"row"}
        justifyContent="center"
        flexWrap={"wrap"}
        columnGap={1}
      >
        ⚠️ Your account is not verified. Please check your e-mail for
        confirmation link.{" "}
        {!resend && (
          <Typography
            fontWeight={600}
            onClick={onResendClick}
            color="white"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              "&:hover": {
                textDecoration: "none",
              },
            }}
            component="a"
          >
            {loading ? "Sending ..." : "Resend verification e-mail"}
          </Typography>
        )}
      </Typography>
    </Stack>
  ) : null;
};

export default ConfirmAlert;
